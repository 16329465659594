import * as React from 'react';
import { ButtonLine } from './ButtonLine';
import { A8Button } from './Button';
import styled from 'styled-components';
import { theme } from '../theme';

export interface ButtonRangeProps {
  options: Array<{ label: string, value: string }>;
  initialValue?: string[];
  onChange?: (value?: string[]) => void;
}


export const SelectEffectFilter = React.forwardRef(({ selection, ...rest }: React.InputHTMLAttributes<HTMLInputElement> & { selection: 'none' | 'first' | 'middle' | 'last' | 'first-last' }, ref: any) => {
  return (<div ref={ref} {...rest} />);
});

export const SelectEffect = styled(SelectEffectFilter)`
  background: ${theme.primaryColor}44;
  padding: 0.25rem;
  transition: all ease-in-out 0.1s;
  ${(p) => {
    switch (p.selection) {
      case "first": return `border-radius: 0.25rem 0rem 0rem 0.25rem;`;
      case "middle": return `border-radius: 0;`;
      case "last": return `border-radius: 0rem 0.25rem 0.25rem 0rem;`;
      case "first-last": return `border-radius: 0.25rem;`
      case "none": return `background: transparent;`;
    }
  }}
`;

export const ToggleButton = styled(A8Button)`
  text-transform: none;
  font-family: ${theme.fontFamily};
  font-weight: 500;
  min-width: 2.6rem;
  border: solid 1px ${theme.primaryColor};
  ${p => p.variant === 'text' ? 'border-color: transparent;' : ''}
  padding: 5px 15px;
`;

export const ButtonRange = ({
  initialValue,
  options,
  onChange,
}: ButtonRangeProps) => {

  //uses
  const [value, setValue] = React.useState<string[]>(initialValue || []);
  const focusIndex = React.useRef<number | null>(null);
  const buttonLineRef = React.useRef<HTMLDivElement>(null);

  const noSelection = value == null || value.length === 0;
  const singleSelection = value != null && value[0] === value[1];
  const regularSelection = value != null && value[0] !== value[1];

  //keyboard navigation
  const handleKeyUp = (e: React.KeyboardEvent<any>) => {
    const move = (mod: number) => {
      e.preventDefault();
      e.stopPropagation();
      const targetIndex = Math.min(Math.max((focusIndex.current != null ? focusIndex.current : -1) + mod, 0), (options.length - 1));
      const div = buttonLineRef.current;
      if (div != null) {
        const element = div.querySelectorAll('button, input')[targetIndex];
        if (element) (element as any).focus();
      }
    }
    const target: any = e.target;
    if (target?.tagName === 'INPUT') { return; }
    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      move(-1);
    }
    else if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      move(+1);
    }
  };

  let handleItemClick: (e: React.MouseEvent<any>) => void;
  if (noSelection || regularSelection) {
    //initial selection
    handleItemClick = (e) => {
      const first = options[e.currentTarget?.dataset.index].value;
      const val = [first, first];
      setValue(val);
      onChange?.(val);
    }
  }
  else {
    if (singleSelection) {
      handleItemClick = (e) => {
        const second = options[e.currentTarget?.dataset.index].value;
        if (second === value[0]) return;
        const val = [value[0], second];
        setValue(val);
        onChange?.(val);
      }
    }
  }

  let selectionStart: number;
  let selectionEnd: number;
  if (value.length)
    options.forEach((x, i) => {
      if (value.indexOf(x.value) > -1) {
        if (selectionStart == null) {
          selectionStart = i;
          if (value[0] === value[1]) {
            selectionEnd = i;
          }
        }
        else {
          selectionEnd = i;
        }
      }
    });

  return (<ButtonLine spacingX={"0px"} spacingY={"1px"} ref={buttonLineRef} onKeyUp={handleKeyUp}>
    {options.map((x, i) => {
      let selected = false;
      let selection: 'none' | 'first' | 'middle' | 'last' | 'first-last' = 'none';
      if (selectionStart != null) {
        //complete selection
        if (i === selectionStart) {
          selected = true;
          if (i === selectionEnd) {
            selection = 'first-last';
          }
          else
            selection = 'first';
        } else if (i === selectionEnd) {
          selected = true;
          selection = 'last';
        }
        else if (i >= selectionStart && i <= selectionEnd) {
          selection = 'middle';
        }
      }
      return (
        <SelectEffect selection={selection} key={x.value}>
          <ToggleButton
            data-index={i}
            onFocus={() => { focusIndex.current = i; }}
            variant={selected ? "contained" : selection === 'middle' ? 'text' : 'outlined'}
            tabIndex={i > 0 ? -1 : undefined}
            onClick={handleItemClick}>
            {x.label}
          </ToggleButton>
        </SelectEffect>
      );
    })}
  </ButtonLine>);
}