import React from 'react'
import styled from 'styled-components'
import { theme } from '../theme'
import { BackgroundA8 } from './Icons/BackgroundA8'
import { LogoWhite } from './Icons/LogoWhite'
import { Typography } from './Typography/Typography'

export interface FooterProps extends React.HTMLAttributes<HTMLDivElement> {
  logoHref?: string
  isLogoVisible?: boolean
  isEightDetailVisible?: boolean
}

const Footer = styled.footer`
  background-color: ${theme.primaryColor};
  padding: 20px;
  display: flex;
  align-items: flex-start;

  & > div {
    max-width: 1248px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .text {
    color: white;
  }
`

const AboutCompany = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 500px;

  @media (max-width: 768px) {
    margin: 20px 0 0;
    flex-direction: column;
    align-items: center;
  }
`

const PrivacyPolicy = styled.div`
  a {
    text-decoration: none;
  }

  a:hover span {
    color: #9494d3;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`

const Copyright = styled.p`
  color: #9494d3;
  margin-left: 50px;

  > span {
    color: #9494d3;
  }

  @media (max-width: 768px) {
    margin: 0;
    padding-top: 20px;
  }
`

const EightDetail = styled.div`
  position: absolute;
  left: 0;
  bottom: 67px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const SimpleFooter: React.FC<FooterProps> = ({
  logoHref = '#',
  isLogoVisible = false,
  isEightDetailVisible = false,
  className = ''
}) => {
  const getCurrentYear = (): number => new Date().getFullYear()
  return (
    <Footer className={className}>
      <div>
        {isEightDetailVisible ? (
          <EightDetail>
            <BackgroundA8 />
          </EightDetail>
        ) : null}
        {isLogoVisible ? (
          <a href={logoHref}>
            <LogoWhite width={120} />
          </a>
        ) : (
          <div style={{ width: 120 }} />
        )}
        <AboutCompany>
          <PrivacyPolicy>
            <Typography variant='Overline' className='text'>
              Company
            </Typography>
            <a
              href='https://www.avenue8.com/privacy-policy/'
              target='_blank'
              rel='noreferrer'
            >
              <Typography variant='Caption' fontWeight={500} className='text'>
                Privacy Policy
              </Typography>
            </a>
          </PrivacyPolicy>
          <Copyright>
            <Typography variant='Caption' fontWeight={500}>
              Copyright © {getCurrentYear()} Avenue 8 Inc. - All Rights
              Reserved.
            </Typography>
          </Copyright>
        </AboutCompany>
      </div>
    </Footer>
  )
}
