import { ButtonBase } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import { Checkmark } from '../components/Icons/Checkmark';
import { theme } from '../theme';

export interface A8RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string,
}

export const RadioButton = React.forwardRef((props: A8RadioButtonProps, ref: any) => {
  const { label, ...rest } = props;
  const labelRef = React.useRef(ref);

  return (<RadioButtonContainer {...props}>
    <label ref={labelRef}>
      <div>
        <span>{label}</span>
      </div>
      <input type="radio" ref={ref} {...rest} />
      <ButtonBase className="av8uirb-circle" disableRipple={props.disabled} onClick={props.disabled ? undefined : (e) => {
        e.preventDefault(); labelRef.current?.click();
      }}>
        <Checkmark />
      </ButtonBase>
    </label>
  </RadioButtonContainer>);
});

const RadioButtonContainer = styled.div<A8RadioButtonProps>`
  /* Customize label (container) */
  & > label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 24px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  }
  /* Hide the browser's default radio button */
  & > label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  }
  & > label > .av8uirb-circle {
    position: absolute;
    top: 0;
    left: 0;
    height: 36px;
    width: 36px;
    background-color: #fff;
    border: ${p => p.disabled ? `1px solid ${theme.disabledColor}` : `1px solid ${theme.primaryColor}`};
    border-radius: 50%;
  }
  & > label > div {
    margin-left: 15px;
    height: 38px;
    display: flex;
    align-items: center;
  }
  & > label > div > span {
    font-family: ${() => theme.fontFamily};
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: ${p => p.disabled ? `${theme.disabledColor}` : `${theme.primaryColor}`};;
  }
  .labelOver {
    margin-left: 15px;
  }
  .MuiTouchRipple-root{
      color: ${theme.primaryColor}!important;
  }
  & > label input:checked ~ .av8uirb-circle {
    background-color: #2a29a6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: background-color linear 0.1s;

    .MuiTouchRipple-root{
      color: white!important;
    }
  }

  & > label input:not(:checked) ~ .av8uirb-circle svg{ 
    display: none;
  }
`