import * as React from 'react';
import { Button, ButtonProps } from "@material-ui/core"
import styled from 'styled-components';
import { theme } from '../theme';

export interface A8ButtonProps extends ButtonProps, A8ButtonExtension {

}

interface A8ButtonExtension {
  wide?: boolean;
}

export const A8ButtonFilter = React.forwardRef((props: A8ButtonProps, ref: any) => {
  const { wide, ...rest } = props;
  return (<Button ref={ref} {...rest} />);
});

export const A8Button = styled(A8ButtonFilter) <A8ButtonProps>`
  text-transform: none;
  font-family: ${() => theme.fontFamily};
  font-weight: 500;
  min-width: ${p => p.wide === true ? '7rem' : '2.6rem'};
  
  ${(p) => {
    if (p.variant === 'contained') {
      return `
        background-color: ${theme.primaryColor};
        color: white;
        &:hover, &:active, &:focus{
          background-color: ${theme.primaryColor}!important;
          color: white;
        }
        &:disabled, &.Mui-disabled{
          color: rgba(255,255,255,.5)!important;
        }
      `
    }
    if (p.variant === 'text') {
      return `
        color: ${theme.primaryColor};
        &:hover, &:active, &:focus{
          background-color: ${theme.primaryColor}16!important;
        }
      `
    }
    if (p.variant === 'outlined') {
      return `
        background-color: transparent;
        color: ${theme.primaryColor};
        border-color: ${theme.primaryColor};
        text-transform: none;
        &:hover, &:active, &:focus{
          background-color: ${theme.primaryColor}16!important;
          border-color: ${theme.primaryColor};
        }
      `;
    }
    return '';
  }}
`;