import * as React from 'react';
import styled from 'styled-components';
import { Typography } from './Typography/Typography';

export interface InputContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    label?: string | React.ReactNode;
    error?: string | null | React.ReactNode;
    children?: React.ReactNode;
    padding?: 'dense' | 'normal' | 'large';
}
 
const InputContainerFilter: React.FC<InputContainerProps> = ({label, children, error, ...props}) => {
    const {padding, ...rest} = props;
    return (<div {...rest}>
        {label ? <Typography padding={padding} display="block" variant="Caption" color="grey">{label}</Typography> : null}
        {children}
        {error ? <Typography variant="Caption" display="block" color="error" >{error}</Typography> : null }
    </div>);
}

export const InputContainer = styled(InputContainerFilter)<InputContainerProps>`
    ${(p) => {
    switch (p.padding) {
      case "dense": return `padding: 4px 0px;`;
      case "normal": return `padding: 12px 0px;`;
      case "large": return `padding: 24px 0px;`;
      default: return `padding: 8px 0px;`;
    }
  }}

`
 