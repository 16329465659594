import React from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'

import { LogoWhite } from './Icons/LogoWhite'
import { theme } from '../theme'
import { Typography } from './Typography/Typography'

const HeaderStyle = styled.div`
  .appBar {
    background-color: ${theme.primaryColor};
    padding: 50px 50px 0;
    width: 100%;
    @media screen and (max-width: 481px) {
      padding: 50px 10px 10px 0px;
    }
  }
  .container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1248px;
    width: 100%;
  }
  .rightHeaderContainer {
    display: flex;
    max-height: 40vh;
    align-items: center;
    justify-content: space-between;
    > *:not(*:first-child) {
      margin-left: 45px;
      @media screen and (max-width: 481px) {
        margin-left: 15px;
      }
    }
  }
  .headerText {
    letter-spacing: 0.25px;
    text-align: right;
    color: var(--white);
    cursor: pointer;
    &:hover {
      font-weight: 500;
    }
  }
  .centeredTextWrapper {
    margin: 30px 0 20px;
  }
  .headerCenteredText {
    color: var(--white);
    letter-spacing: 0px;
    display: flex;
    justify-content: center;
  }
`

type onClickEventHandler = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => void

interface MenuItem {
  text: string | JSX.Element
  onClick: (params?: any) => void
}

export interface A8HeaderProps {
  logoHref?: string
  onLogoClick?: onClickEventHandler
  centralText: string
  menuOptions: Array<MenuItem>
}

const renderMenuItem = (menu: MenuItem, key: number): JSX.Element => (
  <Typography
    key={key}
    variant='Body'
    className='headerText'
    onClick={menu.onClick}
  >
    {menu.text}
  </Typography>
)

export const NonEmbeddedHeader: React.FC<A8HeaderProps> = (
  props: A8HeaderProps
) => {
  const { logoHref = '#', onLogoClick, centralText, menuOptions } = props
  const onLogoClickHandler: onClickEventHandler = (
    mouseEvent: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (onLogoClick) {
      onLogoClick(mouseEvent)
      mouseEvent.preventDefault()
      mouseEvent.stopPropagation()
      mouseEvent.nativeEvent.stopImmediatePropagation()
      mouseEvent.nativeEvent.stopPropagation()
      mouseEvent.nativeEvent.preventDefault()
    }
  }
  return (
    <HeaderStyle>
      <AppBar position='static' className='appBar' elevation={0}>
        <div className='container'>
          <div className='leftHeaderContainer'>
            <a href={logoHref} onClick={onLogoClickHandler}>
              <LogoWhite width={120} />
            </a>
          </div>
          <div className='rightHeaderContainer'>
            {menuOptions.map((menuItem, idx) => renderMenuItem(menuItem, idx))}
          </div>
        </div>
        <div className='centeredTextWrapper'>
          <Typography variant='Subheading' className='headerCenteredText'>
            {centralText}
          </Typography>
        </div>
      </AppBar>
    </HeaderStyle>
  )
}
