import { TextField, TextFieldProps } from '@material-ui/core';
import * as React from 'react';
import { ButtonLine, ButtonLineProps } from './ButtonLine';
import { A8Button, A8ButtonProps } from './Button';
import styled from 'styled-components';
import { theme } from '../theme';

export interface ButtonRadioGroupProps {
  options: Array<{ label: string, value: string }>;
  initialValue?: string;
  buttonLineProps?: ButtonLineProps;
  onChange?: (value?: string) => void;
  customValuesEnabled?: boolean;
  customValueProps?: TextFieldProps;
  normalizeCustomValue?: (input: string) => string | undefined | null;
}

const ButtonWithCheck = ({ checked, ...rest }: A8ButtonProps & { checked?: boolean }) => {
  return <A8Button variant={checked ? "contained" : "outlined"} {...rest} />
}

export const ToggleButton = styled(ButtonWithCheck)`
  text-transform: none;
  font-family: ${theme.fontFamily};
  font-weight: 500;
  min-width: 2.6rem;
  border: solid 1px ${theme.primaryColor};
  padding: 5px 15px;
`;

const CustomValuesTextField = styled(TextField)`
  max-width: 100px;
  margin: 0px;
  input{
    padding-top: 9px!important;
    padding-bottom: 9px!important;
  }
`;

export const ButtonRadioGroup = ({
  normalizeCustomValue,
  initialValue,
  options,
  onChange,
  customValuesEnabled = false,
  customValueProps,
  buttonLineProps
}: ButtonRadioGroupProps) => {
  const [value, setValue] = React.useState(initialValue);
  const inputRef = React.useRef(null);
  const focusIndex = React.useRef<number | null>(null);
  const customValue = React.useRef<string | null>(null);
  const buttonLineRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (initialValue != null && !options.find(x => x.value === initialValue)) {
      const { current } = inputRef;
      if (current) (current as any).value = initialValue;
    }

  }, [initialValue, options]);

  const { inputProps, ...customValuePropsRest } = customValueProps || {};

  return (<ButtonLine spacing="0.25rem" {...buttonLineProps} ref={buttonLineRef} onKeyUp={(e) => {
    const move = (mod: number) => {
      e.preventDefault();
      e.stopPropagation();
      const targetIndex = Math.min(Math.max((focusIndex.current != null ? focusIndex.current : -1) + mod, 0), (options.length + (customValuesEnabled ? 0 : -1)));
      const div = buttonLineRef.current;
      if (div != null) {
        const element = div.querySelectorAll('button, input')[targetIndex];
        if (element) (element as any).focus();
      }
    }
    const target: any = e.target;
    if (target?.tagName === 'INPUT') { return; }
    if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      move(-1);
    }
    else if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      move(+1);
    }
  }}>
    {options.map((x, i) => {
      return (
        <ToggleButton onFocus={() => {
          focusIndex.current = i;
        }} checked={x.value === value} tabIndex={i > 0 ? -1 : undefined} key={x.value} onClick={() => {
          const val = value !== x.value ? x.value : undefined;
          setValue(val); onChange?.(val);
          if (inputRef.current)
            (inputRef.current as any).value = "";
        }}>{x.label}</ToggleButton>
      )
    })}
    {customValuesEnabled && (<div><CustomValuesTextField
      {...customValuePropsRest}
      onFocus={(e) => {
        focusIndex.current = options.length;
        customValue.current = e.target.value;
      }}
      variant="outlined"
      margin="dense"
      size="small"
      InputLabelProps={{ shrink: true }}
      inputProps={{ ...inputProps, tabIndex: -1 }}
      inputRef={inputRef}
      onBlur={e => {
        let val: any = e.target.value;
        if (normalizeCustomValue != null) {
          val = val != null ? normalizeCustomValue(val) : customValue.current;
          (inputRef.current as any).value = val;
        }
        if (customValue.current !== val) {
          onChange?.(val);
          setValue(val);
          if (options.find(x => x.value === val)) {
            const { current } = inputRef;
            (current as any).value = "";
          }
        }
      }}
    /></div>)}
  </ButtonLine>);
}